<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        User List
        <v-spacer></v-spacer>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              v-debounce:300ms="getItems"
              hide-details
              class="pa-0"
            ></v-text-field>
          </v-col>     
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :items="items.results"
        :server-items-length="items.totalResults"
        :options.sync="pagination"
        :footer-props="{'items-per-page-options':[30, 45, 60, -1]}"
        :loading="loading"
        item-key="id"
        class="elevation-1 custom-table"
      >
        <template v-slot:item.profile="{ item }">
          <v-img
            :src="item.profile_pic"
            width="40"
            height="40"
            contain
            class="ma-1 rounded-circle elevation-1"
          ></v-img>
        </template>
        <template v-slot:item.action="{ item }">
          <v-row class="d-flex align-center">
            <v-btn
              color="grey darken-2"
              icon
              dark
              :to="'/user/edit/' + item.id"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-table>      
    </v-card>
    <!--Import Vendors dialog-->
    <v-dialog v-model="import_dialog" persistent max-width="290">
      <v-card :loading="import_loading">
        <v-card-title>Import Attendee</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-file-input
                label="Select file"
                hint="CSV file"
                v-model="import_data.file"
                persistent-hint
                autofocus
                accept=".csv"
                :error-messages="import_error.file"
              ></v-file-input>
            </v-col>
            <v-col>
              <v-btn
                x-small
                color="blue"
                text
                target="_blank"
                :href="FRONT_ASSETS + 'attendee_import_example.csv'"
                block
                >Click here to download sample file</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn small color="red lighten-2" dark @click="import_dialog = false"
            >close</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="importAttendee">save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Import Vendors dialog end-->
    <div style="position: fixed; bottom: 15px; left: 50%" class="d-flex">
      <v-btn
        color="primary"
        fab
        bottom
        right
        to="/user/add"
        small
        class="mr-5"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import { parseParams, getAlphabets } from "../../plugins/helper";
import { mapMutations } from "vuex";
export default {
  name: "Vendorlist",  
  data() {
    return {
      loading: false,
      dialog: false,
      vendor: {
        name: null,
      },
      formData: {
        selected: [],
      },
      search: "",
      type : '',
      items: {
        current_page: 1,
        per_page: 10,
        data: [],
      },
      headers: [
        { text: "Firstname", value: "first_name" },
        { text: "Lastname", value: "last_name" },
        { text: "Company Name", value: "company_name" },
        { text: "Designation", value: "designation" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phone_number" },
        { text: "Profile", value: "profile", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      export_header: [
        { label: "Firstname", field: "first_name" },
        { label: "Lastname", field: "last_name" },
        { label: "Company Name", field: "company_name" },
        { label: "Designation", field: "designation" },
        { label: "Email", field: "email" },
        { label: "Phone Number", field: "phone_number" }        
      ],
      pagination: {
        itemsPerPage: 30,
        sortBy: ["created_at"],
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets(),
      import_dialog: false,
      import_loading: false,
      import_data: {
        file: null,
      },
      import_error: {},
      sponsor_type: [],
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "name";
      paginationData.sponsor_type = this.type
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getDateTime(date_time) {
      if (date_time != "" && date_time != null) {
        let invitation_date = this.moment(date_time);
        return invitation_date.utc().format("MM/DD hh:mm a");
      } else {
        return "";
      }
    },
    sendInvitation() {
      let _self = this;
      if (_self.formData.selected.length > 0) {
        this.loading = "secondary";

        let form_ata = new FormData();

        for (let key in _self.formData.selected) {
          form_ata.append(
            `attendee_ids[${key}]`,
            _self.formData.selected[key].attendee_id
          );
        }

        this.$axios
          .post(`admin/attendee/send_invitation`, form_ata)
          .then((response) => {
            let response_data = response.data;
            if (response_data.status) {
              _self.setAlert({
                show: true,
                variant: "success",
                message: response.data.message,
                dismissCountDown: 5000,
              });
              _self.formData.selected = [];
              _self.getItems();
            }
            _self.loading = false;
          })
          .catch((e) => {
            console.log(e);
            _self.loading = false;
          });
      } else {
        _self.setAlert({
          show: true,
          variant: "danger",
          message: "Please select at least one attendee to send invitation",
          dismissCountDown: 5000,
        });
      }
      console.log(this.selected);
    },
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    getItems() {
      let _self = this;
      this.loading = "secondary";
      let url = parseParams(this.pageData);
      this.$axios
        .get("/admin/attendee/list?" + url)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    updateStatus(attendee_id, is_active) {
      let _self = this;
      this.$axios
        .post("/admin/attendee/update_status", {
          attendee_id: attendee_id,
          is_active: is_active,
        })
        .then((response) => {
          _self.setAlert({
            show: true,
            variant: "success",
            message: response.data.message,
            dismissCountDown: 5000,
          });
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    importAttendee() {
      this.import_loading = true;
      let formData = new FormData();
      formData.append("file", this.import_data.file);
      this.$axios
        .post("/admin/attendee/import_attendee", formData)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            this.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            this.import_dialog = false;
            this.getItems();
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              this.import_error[errors[key].field] = errors[key].message;
            }
          }
          this.import_loading = false;
        })
        .then(() => {
          this.import_loading = false;
        })
        .catch(() => {
          this.import_loading = false;
        });
    },
    getMemberList() {
      this.$axios
        .get(`admin/sponser_type/list?type=ATTENDEE`)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            this.sponsor_type = response_data.data;
          }
          this.sponsor_type.unshift({
            name : 'All',
            sponsor_id : ''
          })
        })
        .catch(() => {});
    },
  },
  mounted : function(){
    //this.getMemberList()
  }
};
</script>